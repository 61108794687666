#root {
    background-color: black;
}

html {
    background-color: black;
}

.welcomeContainer {
    width: 100%;
    height: 325px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#welcomeText {
    font-size: 22px;
    width: 575px;
    max-width: 90%;
    text-align: center;
}

/* on medium screen */
@media (min-width: 576px) { 
    #welcomeText {
        font-size: 30px;
        width: 520px;
    }
}
/* on the big screen */
@media (min-width: 768px) { 
    #welcomeText {
        font-size: 35px;
        width: 600px;
        text-align: left;
    }
    .welcomeContainer {
        flex-direction: row;
    }
}

.welcomeCircleContainer {
    width: 100px;
    height: 100px;
    margin: 0px 15px 8px 15px;
    display: flex;
    justify-content: center;
}

@keyframes welcomePopIn {
    0%   {width: 0px; height: 0px;}
    25%  {width: 100px; height: 100px;}
    50%  {width: 110px; height: 110px;}
    75%  {width: 100px; height: 100px;}
    82%  {width: 90px; height: 90px;}
    100% {width: 100px; height: 100px;}
}

.welcomeCircle {
    width: 0px;
    height: 0px;
    background-color: lightskyblue;
    border-radius: 50%;
}

.welcomeCircle.welcomePop {
    width: 100px;
    height: 100px;
    animation-name: popIn;
    animation-duration: 500ms;
    animation-timing-function: ease-out;
}

.welcomeIllustration {
    width: 101%;
}