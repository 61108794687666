*{
    font-family: arboria, sans-serif;
}

.projectTile {
    width: 360px;
    height: 250px;
    margin: 8px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    background-color: #bdd8e6;
}

.dark {
    color: white;
}

.projectImage {
    display: inline;
    margin: 0 auto;
    width: 100%;
}


.projectTitle {
    position: absolute;
    left: 0px;
    bottom: 0px;
    margin: 0px;
    padding: 5px 12px 3px 12px;
    width: 100%;
}

#projectContainer {
    padding: 75px 0px 45px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 1200ms ease-out, transform 600ms ease-out,
      visibility 1200ms ease-out;
    will-change: opacity, transform, visibility;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}